:root {
	/* Background */
	--dreamsicle: #fff6ee;
	/* Main-Text */
	--vanilla: #fffbf8;
	/* Active-Popup */
	--pumpkin: #ea7f3a;
	/* Inactive-Popup */
	--autumn: #f7cb9c;
	/* Main-Button & and Main-Text */
	--pine: #202d2b;
	/* Inactive-Button & and Inactive-UI */
	--faded-pine: rgba(44, 65, 44, 0.69);
	/* Main-Button-Hover */
	--fresh: #50603b;
	/* Main-Destructive-Button & Text*/
	--strawberry: #a44433;
	/* Destructive-Button-Inactive */
	--faded-strawberry: #a4443380;
	/* Destructive-Button-Hover */
	--tomato: #d35c4c;
}

body {
	margin: 0;
	background-color: var(--dreamsicle);
	font-family: "Poppins", "Rubik", -apple-system, BlinkMacSystemFont,
		"Segoe UI", "Roboto", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

* {
	box-sizing: border-box;
}

.blackLink {
	color: black;
	text-decoration: none;
}

.whiteLink {
	color: white;
	text-decoration: none;
}